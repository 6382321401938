<template>
    <div class="modal fade" id="createCourseModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-medium modal-dialog-centered">
            <div class="modal-content p-4">
                <slot />
            </div>
        </div>
    </div>
</template>


<style scoped >
.modal-medium {
    max-width: 599px !important;
}
</style>
<template>
    <div style="max-width: 1199px;">
        <div class="d-flex justify-content-between align-items-center gap-4">
            <div class="w-100">
                <p class="m-0 mb-2">Home / <b> My Memberships </b></p>
                <h2 class="head-title">My Memberships</h2>

                <div class="mt-4">
                    <label for="sort-by" class="me-2"><b class="text-secondary">Sort By:</b></label>
                    <select id="sort-by">
                        <option value="1" selected>Default</option>
                    </select>
                </div>
            </div>
            <div class="w-100">
                <div class="d-flex justify-content-end align-items-center gap-2">
                    <button class="btn btn-primary create-course-btn py-2" data-bs-toggle="modal"
                        data-bs-target="#createCourseModal">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24L0 64 0 350.5 0 400l0 88c0 13.3 10.7 24 24 24s24-10.7 24-24l0-100 80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30l0-279.7c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52l0-28zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8l0 241.8-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5l0-237z" />
                        </svg>
                        <br>
                        <b class="text-white" style="font-size: 12px;">Create Memberships</b>
                    </button>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-3" v-for="(membership, index) in memberships" :key="index" >
                <MembershipGrid @delete="(id) => selectedItmeId = id" :data="membership" />
            </div>
        </div>

        <CreateModal />
        <div class="modal fade" id="removeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="removeModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Delete Confirmation</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete that item?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteItem" >Delete</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import MembershipGrid from './components/MembershipGrid.vue';
import CreateModal from './components/CreateModal.vue';
import { computed, onMounted, ref } from 'vue';
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';

const store = useStore();
const selectedItmeId = ref(null)

const memberships = computed(() => store.getters["membership/getMemberships"])

onMounted(
    () => {
        store.dispatch("membership/get")
            .catch((e) => {
                toast.error(e.response.data.message || "Error");
            })
    }
)

const deleteItem = () => {
    if (selectedItmeId.value) {
        store.dispatch("membership/remove", {id : selectedItmeId.value})
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Data remove.');
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
    }
}
</script>

<style scoped>
.head-title {
    color: #0d6ffd;
    font-weight: 800;
}

.membership-name {
    font-weight: 800;
    color: black;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.membership-name svg {
    width: 12px;
    cursor: pointer;
}

.create-course-btn svg {
    width: 16px;
    fill: white;
    margin: 8px 0 12px;
}

.import-course-btn svg {
    width: 24px;
    margin: 8px 0 12px;
}

#sort-by {
    max-width: 220px;
    width: 100%;
    border: none;
    padding: 6px;
    background-color: #f5f6f8;
    border: 1px solid #eee;
    border-radius: 5px;
}
</style>
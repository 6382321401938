<template>
    <div class="h-100 d-flex flex-column">
        <AppNavbar />
        <section class="position-relative" style="margin-top: 65px;">
            <aside class="sidebar shadow position-fixed" :class="isOpenSidebar ? 'openSidebar' : ''" >
                <Sidebar @openSidebar="(value) => isOpenSidebar = value" />
            </aside>
            <main class="main-content h-100 p-4">
                <router-view />
            </main>
        </section>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import AppNavbar from '../components/Layouts/AppNavbar.vue';
import Sidebar from '../components/Layouts/Sidebar.vue';

const isOpenSidebar = ref(false)
</script>

<style scoped >
.sidebar {
    float: left;
    max-width: 280px;
    width: 100%;
    height: 100%;
    z-index: 20;
    background-color: white;
}

.main-content {
    margin-left: 288px;
}

@media screen and (max-width: 991px) {
    .sidebar {
        transform: translateX(-280px);
        float: none;
        position: absolute;
        transition: all 0.2s ease-in-out;
    }
    
    .openSidebar {
        transform: translateX(0px) !important;
    }

    .main-content {
        margin-left: 0;
        padding: 8px 16px;
    }
}
</style>
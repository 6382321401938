<template>
    <div class="d-flex gap-3 flex-column">
        <div class="rounded shadow p-3" >
            <p class="fs-5 fw-bold text-black mb-2">Course Description</p>
            <p class="fs-14px">Welcome to the warm-up session of the Workshop! Join Chad as he guides your local newsletter and starting to driv subscribers!</p>

            <div class="d-flex justify-content-center align-items-center" >
                <button class="btn btn-light" >Get Started</button>
            </div>
        </div>
        <div class="rounded shadow p-3" >
            <p class="fs-5 fw-bold text-black mb-2">Meet The Instructor</p>
            <div class="d-flex justify-content-center align-items-center pb-2" >
                <img src="../../../assets/img/user/chad-image.jpg" class="rounded-circle instructor" alt="image" />
            </div>
            <p class="fs-14px">Chad Nicely has been an online coach sir thousands of people just like you have a systems on the internet.</p>
        </div>
        <div class="rounded shadow p-3" >
            <div class="d-flex justify-content-between align-items-center gap-2 mb-1">
                <p class="fs-5 fw-bold text-black mb-0">Points & Badges</p>
                <p class="fs-6 fw-bolder text-primary mb-0">30 pts</p>
            </div>

            <div class="d-flex justify-content-between align-items-center gap-2 mb-4">
                <div>
                    <p class="fs-6 fw-medium text-black mb-0" >Keep Going Chad!</p>
                    <p class="fs-11px fw-medium text-black mb-2" >185 points needed to earn this badge</p>
                    <div class="bg-light" style="height: 3px;" >
                        <div class="h-100 bg-primary" style="width: 75%;" ></div>
                    </div>
                </div>
                <img src="../../../assets/img/about/about-img1.png" class="rounded-circle badge-img" alt="image" />
            </div>

            <div class="d-flex justify-content-center align-items-center" >
                <button class="btn btn-light" >Got To Goal Center</button>
            </div>
        </div>
    </div>
</template>

<style scoped >
.fs-14px {
    font-size: 14px;
}

.fs-11px {
    font-size: 11px;
}

.instructor {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.badge-img {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
}
</style>
<template>
    <div class="single-courses-box">
        <div class="courses-image">
            <router-link to="/single-courses-one" class="d-block image">
                <img :src="image" :alt="data.name" />
            </router-link>
            <div class="price shadow bg-primary">$39</div>
        </div>
        <div class="courses-content p-3">
            <h3>
                <router-link to="/single-courses-one">
                    {{ data.name }}
                </router-link>
            </h3>
            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                <li><i class="flaticon-agenda"></i> 15 Courses</li>
                <li><i class="flaticon-people"></i> 145 Students</li>
            </ul>
            <div class="btn-group d-flex justify-content-center mt-3" role="group" aria-label="Basic example">
                <RouterLink :to="{name : 'Courses', params: {membership_id : data.id}}" type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                </RouterLink>
                <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'eye']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'file-export']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'tag']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'copy']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                    <font-awesome-icon :icon="['fas', 'paintbrush']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm" :class="true ? 'text-success' : 'text-secondary'">
                    <font-awesome-icon :icon="['fas', true ? 'toggle-on' : 'toggle-off']" />
                </button>
                <button type="button" class="btn btn-outline-light btn-sm text-danger" @click="removeItem" data-bs-toggle="modal" data-bs-target="#removeModal">
                    <font-awesome-icon :icon="['fas', 'trash']"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup >
import { computed} from 'vue';

const emit = defineEmits(['delete'])
const props = defineProps({
    data : {
        require : true
    }
})
const removeItem = () => {
    emit('delete', props.data.id)
}

const image = computed(() => `${process.env.VUE_APP_STORAGE_BASE}/${props.data.image}`)
</script>

<style scoped>
.price {
    font-size: 14px !important;
    width: 45px !important;
    height: 45px !important;
    bottom: -24px !important;
    line-height: 47px !important;
    right: 12px !important;
}

.courses-content h3 {
    font-size: 19px !important;
    margin-bottom: 0px !important;
}

.courses-box-footer {
    margin-top: 4px !important;
}

.courses-image img {
    height: 170px;
    object-fit: cover;
    width: 100%;
}
</style>
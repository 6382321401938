<template>
    <Modal>
        <form @submit.prevent="submit">
            <div class="d-flex justify-content-between align-items-center gap-4">
                <h2><b>Create Course</b></h2>
                <button class="btn btn-light" data-bs-dismiss="modal" type="button" ref="closeBtn" @click="resetForm">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
            </div>

            <div class="mt-4">
                <label for="title" class="form-label">Title</label>
                <input type="text" placeholder="Course Title" class="input-filed" v-model="form.title" :class="{ invalid: $v.title.$error }" id="title">
                <span v-if="$v.title.$error">
                    {{ $v.title.required.$invalid ? "Course Title is required" : "" }}
                </span>
            </div>
            <div class="mt-3">
                <label for="description" class="form-label">Short Description</label>
                <textarea class="input-filed" placeholder="Course Description" v-model="form.description" id="description" rows="3"></textarea>
            </div>
            <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="instructor" class="form-label">Choose Instructor</label>
                    <button style="text-decoration: underline;" type="button" class="text-info btn btn-sm btn-outline-light" data-bs-toggle="modal"
                    data-bs-target="#createInstructorModal">Create New Instructor</button>
                </div>
                <select id="instructor" v-model="form.instructor_id" class="input-filed">
                    <option value="null" selected>--Select Instructor--</option>
                    <option v-for="(instructor, index) in instructorList" :key="index" :value="instructor.id">{{ instructor.name }}</option>
                </select>
                <p class="mt-1"><b>Note :</b>You can always add/change the instructor later</p>
            </div>

            <div class="d-flex align-items-center gap-4 mt-4">
                <div>
                    <p class="mb-1 text-black">Course Image</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="courseImgSrc" v-if="courseImgSrc" class="h-100 w-100" style="object-fit: cover;"
                                alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importCourseImg">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleCourseImgUpload" ref="courseImg" class="d-none">
                        <span v-if="$v.image.$error">
                            {{ $v.image.required.$invalid ? "Image is required." : "" }}
                            {{ $v.image.isImage.$invalid ? "Must an image." : "" }}
                            {{ $v.image.maxSize.$invalid ? "Size under 2MB." : "" }}
                        </span>
                    </div>
                </div>

                <div>
                    <p class="mb-1 text-black">Course Logo</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="courseLogoSrc" v-if="courseLogoSrc" class="h-100 w-100"
                                style="object-fit: cover;" alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importCourseLogo">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleCourseLogoUpload" ref="courseLogo" class="d-none">
                        <span v-if="$v.logo.$error">
                            {{ $v.logo.required.$invalid ? "Logo is required." : "" }}
                            {{ $v.logo.isImage.$invalid ? "Must an image." : "" }}
                            {{ $v.logo.maxSize.$invalid ? "Size under 2MB." : "" }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-4 mt-4" :class="$v.logo.$error || $v.image.$error ? 'pt-2' : ''">
                <button class="btn btn-primary btn-lg w-100" :disabled="isLoading"><span v-if="isLoading" class="spinner-border spinner-border-sm text-light me-2" role="status"
                    aria-hidden="true"></span> Save Course</button>
            </div>
        </form>
    </Modal>
    <div class="modal fade" id="createInstructorModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="createInstructorModal" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h1 class="modal-title fs-5">Create New Instructor</h1>
                    <button type="button" class="btn-close" ref="instructorCloseBtn" @click="resetInstructorForm" data-bs-dismiss="modal" data-bs-toggle="modal"
                    data-bs-target="#createCourseModal" aria-label="Close"></button>
                </div>
                <form @submit.prevent="createInstructor">
                    <div class="modal-body">
                        <div>
                            <label for="instructor_name" class="form-label">Instructor Name</label>
                            <input type="text" placeholder="Instructor Name" class="input-filed" v-model="instructorForm.name" :class="{ invalid: $v_instructor.name.$error }" id="instructor_name">
                            <span v-if="$v_instructor.name.$error">
                                {{ $v_instructor.name.required.$invalid ? "Instructor name is required" : "" }}
                            </span>
                        </div>
                    </div>
                    <div class="modal-footer border-top-0">
                        <button type="button" class="btn btn-light" data-bs-toggle="modal"
                        data-bs-target="#createCourseModal" @click="resetInstructorForm" data-bs-dismiss="modal">Close</button>
                        <button type="submit" class="btn btn-primary" >Create Instructor</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import Modal from '../../../components/Common/Modal.vue';
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute()

const form = ref({
    title: null,
    description: null,
    logo: null,
    image: null,
    instructor_id: null
});

const instructorForm = ref({
    name : null
})

const imageValidator = helpers.withParams(
    { type: 'image' },
    (value) => {
        return value ? ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value.type) : true
    }
)

const maxSizeValidator = helpers.withParams(
    { max: 2048 },
    (value) => {
        return value ? value.size <= 2048 * 1024 : true
    }
)

const rules = computed(() => {
    return {
        title: { required },
        logo: {
            required,
            isImage: imageValidator,
            maxSize: maxSizeValidator
        },
        image: {
            required,
            isImage: imageValidator,
            maxSize: maxSizeValidator
        }
    }
})

const instructorRules = computed(() => {
    return {
        name : {required},
    }
})

const $v = useVuelidate(rules, form);
const $v_instructor = useVuelidate(instructorRules, instructorForm);

const closeBtn = ref(null)
const instructorCloseBtn = ref(null)

const courseImg = ref(null)
const courseLogo = ref(null)

const courseImgSrc = ref(null)
const courseLogoSrc = ref(null)

const importCourseImg = () => {
    courseImg.value.click()
};
const importCourseLogo = () => {
    courseLogo.value.click()
};

const handleCourseImgUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        courseImgSrc.value = URL.createObjectURL(file);
        form.value.image = file
    }
}

const handleCourseLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        courseLogoSrc.value = URL.createObjectURL(file);
        form.value.logo = file
    }
}

const resetForm = () => {
    $v.value.$reset();
    form.value.title = null
    form.value.description = null
    form.value.instructor_id = null
    form.value.logo = null
    form.value.image = null
    courseImgSrc.value = null
    courseLogoSrc.value = null
}

const resetInstructorForm = () => {
    $v_instructor.value.$reset();
    instructorForm.value.name = null
}
const isLoading = ref(false);
const submit = () => {
    $v.value.$validate();
    if ($v.value.$invalid) {
        return
    }
    isLoading.value = true
    const formData = new FormData();

    formData.append('title', form.value.title);
    formData.append('description', form.value.description);
    formData.append('membership_id', route.params.membership_id);
    formData.append('instructor_id', form.value.instructor_id);
    formData.append('logo', form.value.logo);
    formData.append('image', form.value.image);

    store.dispatch("courses/add", formData)
        .then(({ data }) => {
                isLoading.value = false
            if (data.status) {
                toast.success(data.message || 'Course added.');
                closeBtn.value.click()
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            isLoading.value = false
            toast.error(e.response.data.message || "Error");
        })
}

const instructorList = computed(() => store.getters["courses/getInstructor"])

onMounted(() => {        
    store.dispatch("courses/getInstructor", route.params.membership_id)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
})

const createInstructor = () => {
    $v_instructor.value.$validate();
    if ($v_instructor.value.$invalid) {
        return
    }

    const formData = new FormData();

    formData.append('name', instructorForm.value.name);
    formData.append('membership_id', route.params.membership_id);

    store.dispatch("courses/addInstructor", formData)
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Data added.');
                instructorCloseBtn.value.click()
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
}
</script>

<style scoped>
.input-filed {
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-uploader-icon {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-110%, 10%);
    width: 22px;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.image-uploader-icon svg {
    width: 10px;
}

.image-uploader-div {
    border: 1px solid #ccc;
    max-width: 140px;
    width: 140px;
    height: 85px;
}

.membership-url-group {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    color: black;
}

.membership-url-group input {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
}
</style>
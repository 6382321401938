import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Toaster from "@meforma/vue-toaster";
import BootstrapVue3 from "bootstrap-vue-3";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(fas, far);

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import "./assets/custom.scss";

import 'vue3-toastify/dist/index.css';
import Vue3Toastify from 'vue3-toastify';

const app = createApp(App).use(router).use(store).use(Toaster);
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(Vue3Toastify, {
    autoClose: 3000,
    position: "top-right",
});
app.use(VueAxios, axios);
app.use(BootstrapVue3).mount("#app");

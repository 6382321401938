<template>
    <div class="single-courses-item shadow">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="courses-image">
                    <img :src="image" class="course-image" alt="image" />
                    <router-link to="/single-courses-one" class="link-btn"></router-link>
                </div>
            </div>
            <div class="col-lg-8 col-md-8">
                <div class="courses-content d-flex flex-column p-3 h-100">
                    <div class="d-flex justify-content-between gap-4">
                        <h3 class="line-clam-2">
                            <router-link to="/single-courses-one">
                                {{ data.title }}
                            </router-link>
                        </h3>
                        <span class="price text-primary fs-5">$99</span>
                    </div>
                    <ul class="courses-content-footer d-flex justify-content-between align-items-center mt-1">
                        <li><i class="flaticon-agenda"></i> 14 Lessons</li>
                        <li><i class="flaticon-people"></i> 100 Students</li>
                    </ul>

                    <div class="btn-group d-flex justify-content-center mt-auto" role="group" aria-label="Basic example">
                        <RouterLink :to="{name : 'MembershipStep1', params: {membership_id : route.params.membership_id},query: { course_id: data.id }}" type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                        </RouterLink>
                        <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'eye']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'file-export']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'tag']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'copy']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm text-secondary">
                            <font-awesome-icon :icon="['fas', 'paintbrush']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm"
                            :class="true ? 'text-success' : 'text-secondary'">
                            <font-awesome-icon :icon="['fas', true ? 'toggle-on' : 'toggle-off']" />
                        </button>
                        <button type="button" class="btn btn-outline-light btn-sm text-danger" @click="removeItem" data-bs-toggle="modal" data-bs-target="#removeModal">
                            <font-awesome-icon :icon="['fas', 'trash']" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['delete'])
const props = defineProps({
    data : {
        require : true
    }
})
const route = useRoute()

const removeItem = () => {
    emit('delete', props.data.id)
}

const image = computed(() => `${process.env.VUE_APP_STORAGE_BASE}/${props.data.image}`)
</script>

<style scoped >
.course-image {
    height: 160px;
    width: 160px;
}
</style>
<template>
    <div class="row gap-xl-0 gap-4">
        <div class="col-xl-3">
            <div>
                <h4 class="mb-3 fw-bold">Course Menu</h4>
                <div class="nav flex-column menu-nev gap-0 nav-pills">
                    <p class="nav-link active rounded-0 text-left fw-semibold"> <font-awesome-icon :icon="['fas', 'circle']" /> Load Your Profile Pic</p>
                    <p class="nav-link rounded-0 border border-top-0 text-dark text-left fw-semibold"> <font-awesome-icon :icon="['fas', 'circle']" /> Can You Tell Me More?</p>
                    <p class="nav-link rounded-0 border border-top-0 text-dark text-left fw-semibold"> <font-awesome-icon :icon="['fas', 'circle']" /> Naming Our Newsletter</p>
                    <p class="nav-link rounded-0 border border-top-0 text-dark text-left fw-semibold"> <font-awesome-icon :icon="['fas', 'circle']" /> Lets Get A Domain</p>
                    <p class="nav-link rounded-0 border border-top-0 text-dark text-left fw-semibold"> <font-awesome-icon :icon="['fas', 'circle']" /> Build Out Our Logo</p>
                </div>
            </div>
        </div>
        <div class="col-xl-6">
            <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1002350379?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Upgrade_Basic_Platinum"></iframe></div>
            <h4 class="my-3 fw-bold">Lead Your Profile Picture</h4>

            <ul class="nav nav-underline bg-light rounded-2 px-2">
                <li class="nav-item">
                    <p class="nav-link text-dark active border-primary">Lesson Description</p>
                </li>
                <li class="nav-item">
                    <p class="nav-link text-dark">Resources</p>
                </li>
                <li class="nav-item">
                    <p class="nav-link text-dark">Goals</p>
                </li>
                <li class="nav-item">
                    <p class="nav-link text-dark">Q&A</p>
                </li>
            </ul>

            <p class="mt-4">In the first lesson, we'll guide you to upload your profile picture so we can recognize and celebrate your achivements together!</p>

            <img src="../../assets/img/user/comment.png" alt="comment">
        </div>
        <div class="col-xl-3">
            <div class="d-flex gap-3 flex-column">
                <div class="rounded shadow p-3" >
                    <div class="d-flex justify-content-between align-items-center gap-2 mb-1">
                        <p class="fs-5 fw-bold text-black mb-0">Points & Badges</p>
                        <p class="fs-6 fw-bolder text-primary mb-0">30 pts</p>
                    </div>

                    <div class="d-flex justify-content-between align-items-center gap-2 mb-4">
                        <div>
                            <p class="fs-6 fw-medium text-black mb-0" >Keep Going Chad!</p>
                            <p class="fs-11px fw-medium text-black mb-2" >185 points needed to earn this badge</p>
                            <div class="bg-light" style="height: 3px;" >
                                <div class="h-100 bg-primary" style="width: 75%;" ></div>
                            </div>
                        </div>
                        <img src="../../assets/img/about/about-img1.png" class="rounded-circle badge-img" alt="image" />
                    </div>

                    <div class="d-flex justify-content-center align-items-center" >
                        <button class="btn btn-light" >Got To Goal Center</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped >
.nav-underline .nav-item .active {
 border-bottom: 3px solid;
}

.nav-underline p {
    font-size: 18px;
    font-weight: 700;
    cursor: pointer;
}

.fw-13px {
    font-size: 13px !important;
    line-height: 1;
}

.fw-14px {
    font-size: 14px;
}

.user-avatar {
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.fs-11px {
    font-size: 11px;
}

.instructor {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

.badge-img {
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
}

.menu-nev .nav-link {
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 0;
}

.nav-link svg {
    font-size: 6px;
}
</style>
<template>
    <div class="d-flex gap-3 flex-column">
        <div class="rounded shadow p-3" >
            <p class="fs-5 fw-bold text-black mb-2">Recent Activity</p>
            
            <div class="d-flex gap-3 flex-column mt-3" >
                <div class="d-flex gap-2 pb-2 position-relative border-bottom" v-for="i in 8" :key="i">
                    <img src="../../../assets/img/user/user1.jpg" class="rounded-2 user-avatar" alt="image" />
                    <div>
                        <p class="m-0 text-black fw-semibold">Bernard earned a badge</p>
                        <p class="fs-12px m-0">Dec 10, 2024 at 09:49 PM</p>
                    </div>
                    <font-awesome-icon class="activity-emoji" :icon="['far', 'comment']" />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.user-avatar {
    width: 46px;
    height: 46px;
    object-fit: cover;
}

.fs-12px {
    font-size: 12px;
}

.activity-emoji {
    position: absolute;
    bottom: 8px;
    right: 0;
    font-size: 18px;
}
</style>
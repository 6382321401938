<template>
    <div class="modal fade" id="createLessonModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-medium modal-dialog-centered">
            <div class="modal-content p-4">
                <form @submit.prevent="submit">
                    <div class="d-flex justify-content-between align-items-center gap-4">
                        <h2><b>Update Lesson</b></h2>
                        <button class="btn btn-light" data-bs-dismiss="modal" type="button" ref="closeBtn"
                            @click="resetForm">
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </button>
                    </div>

                    <div class="mt-4">
                        <label for="name" class="form-label">Name</label>
                        <input type="text" placeholder="Lesson Name" class="input-filed" v-model="form.name"
                            :class="{ invalid: $v.name.$error }" id="name">
                        <span v-if="$v.name.$error">
                            {{ $v.name.required.$invalid ? "Lesson name is required" : "" }}
                        </span>
                    </div>
                    <div class="mt-3">
                        <label for="description" class="form-label">Short Description</label>
                        <textarea class="input-filed" placeholder="Course Description" v-model="form.description"
                            id="description" rows="3"></textarea>
                    </div>

                    <div class="mt-3">
                        <label for="long_description" class="form-label">Long Description</label>
                        <textarea class="input-filed" placeholder="Course Description" v-model="form.long_description"
                            id="long_description" rows="3"></textarea>
                    </div>

                    <div class="d-flex align-items-center gap-4 mt-4">
                        <div>
                            <p class="mb-1 text-black">Lesson Image</p>
                            <div class="image-uploader-div rounded-2 position-relative">
                                <div class="bg-secondary rounded-2 overflow-hidden h-100">
                                    <img :src="lessonImgSrc" v-if="lessonImgSrc" class="h-100 w-100"
                                        style="object-fit: cover;" alt="default Image">
                                    <img src="../../../../assets/img/no-image.png" v-else class="h-100"
                                        style="object-fit: cover;" alt="default Image">
                                </div>
                                <div class="image-uploader-icon" @click="importlessonImg">
                                    <font-awesome-icon :icon="['fas', 'pen']" />
                                </div>
                                <input type="file" @change="handlelessonImgUpload" ref="lessonImg" class="d-none">
                                <span v-if="$v.image.$error">
                                    {{ $v.image.required.$invalid ? "Image is required." : "" }}
                                    {{ $v.image.isImage.$invalid ? "Must an image." : "" }}
                                    {{ $v.image.maxSize.$invalid ? "Size under 2MB." : "" }}
                                </span>
                            </div>
                        </div>

                    </div>
                    <div class="d-flex justify-content-end align-items-center gap-4 mt-4"
                        :class="$v.image.$error ? 'pt-2' : ''">
                        <button class="btn btn-primary btn-lg w-100" :disabled="isLoading"><span v-if="isLoading"
                                class="spinner-border spinner-border-sm text-light me-2" role="status"
                                aria-hidden="true"></span> Save Lesson</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const props = defineProps({
    lesson : {
        default : {}
    }
})

const form = ref({
    name: null,
    description: null,
    long_description: null,
    image: null,
});

onMounted(() => {
    if (props.lesson) {   
        form.value.name = props.lesson.name
        form.value.description = props.lesson.description
        form.value.long_description = props.lesson.long_description
        lessonImgSrc.value = props.lesson.image
    }
})

const imageValidator = helpers.withParams(
    { type: 'image' },
    (value) => {
        return value ? ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value.type) : true
    }
)

const maxSizeValidator = helpers.withParams(
    { max: 2048 },
    (value) => {
        return value ? value.size <= 2048 * 1024 : true
    }
)

const rules = computed(() => {
    return {
        name: { required },
        image: {
            required,
            isImage: imageValidator,
            maxSize: maxSizeValidator
        }
    }
})

const $v = useVuelidate(rules, form);

const closeBtn = ref(null)

const lessonImg = ref(null)

const lessonImgSrc = ref(null)

const importlessonImg = () => {
    lessonImg.value.click()
};

const handlelessonImgUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        lessonImgSrc.value = URL.createObjectURL(file);
        form.value.image = file
    }
}

const resetForm = () => {
    $v.value.$reset();
    form.value.name = null
    form.value.description = null
    form.value.long_description = null
    form.value.image = null
    lessonImgSrc.value = null
}

const isLoading = ref(false);
const submit = () => {
    $v.value.$validate();
    if ($v.value.$invalid) {
        return
    }
    isLoading.value = true
    const formData = new FormData();

    formData.append('id', props.lesson.id);
    formData.append('name', form.value.name);
    formData.append('membership_id', route.params.membership_id);
    formData.append('course_id', props.lesson.course_id);
    formData.append('type', 'video');
    formData.append('description', form.value.description);
    formData.append('long_description', form.value.long_description);
    formData.append('image', form.value.image);

    store.dispatch("courses/updateLesson", formData)
        .then(({ data }) => {
            isLoading.value = false
            if (data.status) {
                toast.success(data.message || 'Course added.');
                closeBtn.value.click()
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            isLoading.value = false
            toast.error(e.response.data.message || "Error");
        })
}

</script>

<style scoped>
.input-filed {
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-uploader-icon {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-110%, 10%);
    width: 22px;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.image-uploader-icon svg {
    width: 10px;
}

.image-uploader-div {
    border: 1px solid #ccc;
    max-width: 140px;
    width: 140px;
    height: 85px;
}

.membership-url-group {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    color: black;
}

.membership-url-group input {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
}
</style>
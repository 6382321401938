<template>
    <div class="h-100 position-relative">
        <button class="position-absolute sidebar-menu-icon-div d-lg-none d-block" @click="$emit('openSidebar', isOpenSidebar), isOpenSidebar =! isOpenSidebar" >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/>
            </svg>
        </button>
        <ul class="d-flex justify-content-between align-items-center sidebar-tab">
            <li>
                <router-link :to="{name : 'Memberships'}" >
                    <font-awesome-icon :icon="['fas', 'gauge']" />
                </router-link>
            </li>
            <li>
                <font-awesome-icon :icon="['far', 'eye']" />
            </li>
            <!-- <li>
                <font-awesome-icon :icon="['fas', 'graduation-cap']" />
            </li> -->
        </ul>

        <ul class="d-flex flex-column gap-2 sidebar-items" v-if="!isMembershipLayout">
            <li class="w-100"> 
                <router-link :to="membershipData && membershipData.id ? `/app/membership/${membershipData.id}/course/step1` : '#'">
                    <div class="d-flex align-items-center gap-4 px-3" >
                        <div class="sidebar-items-icon-div">
                            <font-awesome-icon :icon="['fas', 'location-dot']"  :class="{ active: ['MembershipStep1', 'Courses'].includes($route.name) }"/>
                        </div>
                        <div>
                            <p class="m-0 sidebar-items-title" :class="{ 'text-primary': ['MembershipStep1', 'Courses'].includes($route.name) }">Step #1</p>
                            <p class="sidebar-items-cation">My Courses</p>
                        </div>
                    </div>
                </router-link>
            </li>
            <li class="w-100"> 
                <router-link to="/">
                    <div class="d-flex align-items-center gap-4 px-3" >
                        <div class="sidebar-items-icon-div">
                            <font-awesome-icon :icon="['fas', 'phone-volume']" />
                        </div>
                        <div>
                            <p class="m-0 sidebar-items-title">Step #2</p>
                            <p class="sidebar-items-cation">Pricing Options</p>
                        </div>
                    </div>
                </router-link>
            </li>
            <li class="w-100"> 
                <router-link to="/">
                    <div class="d-flex align-items-center gap-4 px-3" >
                        <div class="sidebar-items-icon-div">
                            <font-awesome-icon :icon="['far', 'clock']" />
                        </div>
                        <div>
                            <p class="m-0 sidebar-items-title">Step #3</p>
                            <p class="sidebar-items-cation">Access Emails</p>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>

        <ul class="d-flex flex-column gap-2 sidebar-items" v-else>
            <li class="w-100"> 
                <router-link :to="{name : 'Memberships'}">
                    <div class="d-flex align-items-center gap-4 px-3" >
                        <div class="sidebar-items-icon-div">
                            <font-awesome-icon :icon="['fas', 'location-dot']" />
                        </div>
                        <div>
                            <p class="m-0 sidebar-items-title">Memberships</p>
                            <p class="sidebar-items-cation">Repository of all your memberships</p>
                        </div>
                    </div>
                </router-link>
            </li>
            <li class="w-100"> 
                <router-link to="/">
                    <div class="d-flex align-items-center gap-4 px-3" >
                        <div class="sidebar-items-icon-div">
                            <font-awesome-icon :icon="['fas', 'phone-volume']" />
                        </div>
                        <div>
                            <p class="m-0 sidebar-items-title">New Updates</p>
                            <p class="sidebar-items-cation">The latest and greatest the</p>
                        </div>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script setup >
import {onMounted, ref ,computed} from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";

const isOpenSidebar = ref(false)
const isMembershipLayout = ref(false)
const route = useRoute()
const router = useRouter()
const store = useStore()

onMounted(() => {
    isMembershipLayout.value = route.name == 'Memberships'
})
    
router.afterEach((to) => {
  isMembershipLayout.value = to.name == 'Memberships'
})
const membershipData = computed(() => store.getters["membership/getSelected"]);

</script>

<style scoped >
.sidebar-menu-icon-div {
    padding: 8px;
    background-color: white;
    top: 0;
    left: 100%;
    box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.15) !important;
    fill: #aaa;
    border: none;
}

.sidebar-menu-icon-div svg {
    width: 20px;
}

.sidebar-tab {
    list-style: none;
    padding: 20px 20px;
    background-color: #0d6ffd;
}

.sidebar-tab li:first-child svg {
    font-size: 30px;
}

.sidebar-tab svg {
    font-size: 32px;
    color: white !important;
}

.sidebar-tab li {
    cursor: pointer;
}

.sidebar-items {
    list-style: none;
    padding: 0;
    margin-top: 32px;
}

.sidebar-items svg {
    font-size: 28px;
    color: #bbb;
}
.sidebar-items svg.active {
    color: #0d6cf8;
}

.sidebar-items-icon-div {
    aspect-ratio: 1;
    background-color: #f5f6f8;
    width: 68px;
    display: grid;
    place-items: center;
}

.sidebar-items .router-link-active svg {
    color: #0d6ffd !important;
}

.sidebar-items-title {
    font-size: 20px;
    font-weight: 900;
    line-height: 120%;
    color: black;
}

.sidebar-items-cation {
    font-size: 15px;
    line-height: 110%;
    max-width: 157px;
    margin-top: 2px;
}
</style>
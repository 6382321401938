<template>
    <div>
        <!-- <p><b> Course Name </b></p> -->
        <VueDraggableNext class="d-flex flex-column gap-4" handle=".drag-handle" ghost-class="opacity-100" animation="500" @change="onUpdateOrder" :list="courseList">
            <div v-for="(course, index) in courseList" :key="index" >
                <div
                    class="bg-custom-gray rounded-1 overflow-hidden shadow d-flex justify-content-between align-items-center gap-5 disable-effect " :class="index == perentCollapse ? 'remove-disable-effect' : ''">
                    <img :src="getImage(course.image)"
                        style="height: 72px; width: 112px;" :alt="course.title">

                    <div class="d-flex justify-content-around align-items-center flex-grow-1">
                        <div>
                            <p class="text-black mb-0 tab-title">On the Page</p>
                            <div class="d-flex justify-content-center">
                                <div class="btn-group d-flex justify-content-center" role="group"
                                    aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-primary">
                                        <font-awesome-icon :icon="['fas', 'play']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-primary">
                                        <font-awesome-icon :icon="['fas', 'file-import']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="text-black mb-0 tab-title">Add Below</p>
                            <div class="d-flex justify-content-center">
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-primary" @click="addLesson(course)">
                                        <font-awesome-icon :icon="['fas', 'video']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-primary">
                                        <font-awesome-icon :icon="['fas', 'tag']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p class="text-black mb-0 tab-title">Extra Options</p>
                            <div class="d-flex justify-content-center">
                                <div class="btn-group " role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-primary">
                                        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-primary">
                                        <font-awesome-icon :icon="['fas', 'rotate']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center gap-4">
                        <div>
                            <p class="text-black mb-0 tab-title">Access Options</p>
                            <div class="btn-group d-flex justify-content-center" @click="removeItem(course.id)" data-bs-toggle="modal" data-bs-target="#removeModal">
                                <button type="button" class="btn btn-light btn-sm text-primary">
                                    <font-awesome-icon :icon="['fas', 'trash']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm"
                                    :class="isActive ? 'text-success' : 'text-primary'" @click="isActive = !isActive">
                                    <font-awesome-icon :icon="['fas', isActive ? 'toggle-on' : 'toggle-off']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm text-primary">
                                    <font-awesome-icon :icon="['fas', 'eye']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm text-primary">
                                    <font-awesome-icon :icon="['fas', 'download']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm text-primary">
                                    <font-awesome-icon :icon="['fas', 'copy']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm text-primary">
                                    <font-awesome-icon :icon="['fas', 'file-export']" />
                                </button>
                            </div>
                        </div>
                        <div>
                            <button class="btn btn-outline-light text-secondary ms-4 drag-handle">
                                <font-awesome-icon :icon="['fas', 'grip-vertical']" class="fs-5" />
                            </button>
                            <button class="btn btn-outline-light text-secondary me-2" @click="perentCollapse == index ? perentCollapse = null : perentCollapse = index">
                                <font-awesome-icon :icon="['fas', perentCollapse == index ? 'chevron-down' : 'chevron-up']" />
                            </button>
                        </div>
                    </div>
                </div>

                <VueDraggableNext animation="500" handle=".drag-child-handle" class="d-flex flex-column gap-3 mt-3" v-if="perentCollapse == index">
                    <div class="rounded-1 overflow-hidden shadow d-flex justify-content-between align-items-center gap-5 py-2 disable-effect"
                        v-for="(lesson, index) in course.lessons" :key="index">
                        <div class="d-flex gap-3 align-items-center" >
                            <button class="btn btn-outline-light text-dark ms-2 px-2">
                                <font-awesome-icon :icon="['fas', 'chevron-down']" />
                            </button>
                            <p class="text-dark mb-0"><b> {{ lesson.name }} </b></p>
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-light btn-sm text-dark">
                                    <font-awesome-icon :icon="['fas', 'pen']" />
                                </button>
                                <button type="button" class="btn btn-light btn-sm text-dark">
                                    <font-awesome-icon :icon="['fas', 'paperclip']" />
                                </button>
                            </div>
                        </div>

                        <div class="d-flex justify-content-around align-items-center flex-grow-1">
                            <div> 
                                <p class="text-dark mb-0 tab-title">On the Page</p>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-dark" data-bs-toggle="modal" data-bs-target="#createLessonModal">
                                        <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'play']" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p class="text-dark mb-0 tab-title">Menu Banks</p>
                                <div class="btn-group" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'link']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'trophy']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'table-cells-large']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'comment']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'share-nodes']" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <p class="text-dark mb-0 tab-title">Extra Options</p>
                                <div class="btn-group " role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'droplet']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark px-3">
                                        <font-awesome-icon :icon="['fas', 'ellipsis-vertical']" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center gap-4">
                            <div>
                                <p class="text-dark mb-0 tab-title">Quick Options</p>
                                <div class="btn-group d-flex justify-content-center" role="group" aria-label="Basic example">
                                    <button type="button" class="btn btn-light btn-sm text-dark" @click="$emit('deleteLesson', lesson)" data-bs-toggle="modal" data-bs-target="#removeLessonModal">
                                        <font-awesome-icon :icon="['fas', 'trash']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm"
                                        :class="isActive ? 'text-success' : 'text-dark'" @click="updateLesson(lesson)">
                                        <font-awesome-icon :icon="['fas', lesson.is_active ? 'toggle-on' : 'toggle-off']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'eye']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <!-- <font-awesome-icon :icon="['fas', 'download']" /> -->
                                        <font-awesome-icon :icon="['fas', 'copy']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'rotate']" />
                                    </button>
                                    <button type="button" class="btn btn-light btn-sm text-dark">
                                        <font-awesome-icon :icon="['fas', 'arrows-up-down-left-right']" />
                                    </button>
                                </div>
                            </div>
                            <div>
                                <button class="btn btn-outline-light text-secondary mx-2 drag-child-handle">
                                    <font-awesome-icon :icon="['fas', 'grip-vertical']" class="fs-5" />
                                </button>
                            </div>
                        </div>
                    </div>
                </VueDraggableNext>
            </div>
        </VueDraggableNext>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next'
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { toast } from 'vue3-toastify';

const isActive = ref(true)
const perentCollapse = ref(null)
const store = useStore()
const route = useRoute()

const emit = defineEmits(['delete'])
const props = defineProps({
    data : {
        require : true
    }
})

const courseList = computed(() => store.getters["courses/getCoursesWithSort"])

const removeItem = (id) => {
    emit('delete', id)
}

const getImage = (src) => `${process.env.VUE_APP_STORAGE_BASE}/${src}`

const onUpdateOrder = (event) => {
    console.log(event.moved);
    const { element, newIndex, oldIndex } = event.moved;
    let payload = {
        id : element.id,
        membership_id : route.params.membership_id,
        new_index : newIndex,
        old_index : oldIndex
    }
    store.dispatch("courses/updateIndex", payload)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
}

const addLesson = (course) => {
    let maxNumber = 0;

    course.lessons.forEach(video => {
        const match = video.name.match(/#(\d+)/);
        if (match) {
            const number = parseInt(match[1], 10);
            if (number > maxNumber) {
                maxNumber = number;
            }
        }
    });

    let payload = {
        name : `Video #${String(maxNumber + 1).padStart(2, '0')}`,
        membership_id : route.params.membership_id,
        course_id : course.id,
        type : 'video',
    }
    
    store.dispatch("courses/addLesson", payload)
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Lesson added.');
            } else {
                toast.error(data.message || "Error");
            }
        })
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
}

const updateLesson = (lesson) => {
    let payload = {
        ...lesson, is_active : !lesson.is_active 
    }

    store.dispatch("courses/updateLesson", payload)
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Status changed.');
            } else {
                toast.error(data.message || "Error");
            }
        })
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
}
</script>

<style scoped>
.width-4 {
    width: 16px;
}

.width-3 {
    width: 12px;
}

.tab-title {
    font-size: 14px;
    font-weight: 600;
}

.disable-effect > div {
    opacity: .3;
    transition: opacity 0.5s ease;
}

.remove-disable-effect > div {
    opacity: 1 !important;
    transition: opacity 0.5s ease;
}

.disable-effect:hover > div {
    opacity: 1;
    transition: opacity 0.5s ease;
}

.bg-custom-gray {
    background-color: #f5f6f8;
}
</style>
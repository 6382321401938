<template>
    <div style="max-width: 1199px;">
        <div class="d-flex justify-content-between align-items-center gap-4">
            <div class="w-100">
                <p class="m-0 mb-2">
                <router-link to="/">Home</router-link> / 
                <router-link to="/app/memberships">{{ membershipData.name }}
                </router-link>  / <b> My Course  </b></p>
                <h2 class="head-title">Step #1 - My Course</h2>
                <p>In this step you will be able to create multiple courses inside of your membership and be able to
                    create your lessons as well</p>
                <div class="mt-4">
                    <label for="select-course" class="me-2"><b class="text-secondary">Select Course:</b></label>
                    <select id="select-course">
                        <option selected>All</option>
                        <option v-for="(course , index) in coursesList" :value="course.id" :key="index">{{ course.title }}</option>
                    </select>
                </div>
            </div>
            <div class="w-100">
                <div class="membership-name">
                    <p class="m-0" v-if="!isEditMembershipName">{{ membershipData.name }}</p>
                    <input type="text" v-else v-model="editMembershipName" class="membership-input">
                    <font-awesome-icon :icon="['fas', 'pen']" v-if="!isEditMembershipName" @click="isEditMembershipName = true" />
                    <font-awesome-icon :icon="['fas', 'check']" v-else @click="changeMembershipName" />
                </div>
                <div class="d-flex justify-content-end align-items-center gap-2">
                    <RouterLink :to="{name : 'Courses', params: {membership_id : route.params.membership_id}}" class="btn btn-primary create-course-btn py-2">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24L0 64 0 350.5 0 400l0 88c0 13.3 10.7 24 24 24s24-10.7 24-24l0-100 80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30l0-279.7c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52l0-28zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8l0 241.8-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5l0-237z" />
                        </svg>
                        <br>
                        <b class="text-white" style="font-size: 14px;">Create Course</b>
                    </RouterLink>
                    <button class="shadow btn btn-white import-course-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M320 96L192 96 144.6 24.9C137.5 14.2 145.1 0 157.9 0L354.1 0c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128l128 0c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96L96 512c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4c0 0 0 0 0 0s0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20l0 14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1c0 0 0 0 0 0s0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4l0 14.6c0 11 9 20 20 20s20-9 20-20l0-13.8c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15c0 0 0 0 0 0l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7l0-13.9z" />
                        </svg>
                        <br>
                        <b style="font-size: 14px;">Import Course</b>
                    </button>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <CourseList @delete="(id) => selectedItmeId = id" @deleteLesson="(lesson) => selecetdLesson = lesson" :data="coursesList"/>
        </div>

        <Modal>
            <div class="d-flex justify-content-between align-items-center gap-4">
                <h2><b>Create Course</b></h2>
                <button class="btn btn-light" data-bs-dismiss="modal">
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
            </div>

            <div class="mt-4">
                <label for="title" class="form-label">Title</label>
                <input type="text" placeholder="Course Name" class="input-filed" id="title">
            </div>
            <div class="mt-3">
                <label for="description" class="form-label">Short Description</label>
                <textarea class="input-filed" placeholder="Course Description" id="description" rows="3"></textarea>
            </div>
            <div class="mt-3">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="instructor" class="form-label">Choose Instructor</label>
                    <p style="text-decoration: underline;" class="text-info">Create New Instructor</p>
                </div>
                <select id="instructor" class="input-filed">
                    <option value="null" selected>--Select Instructor--</option>
                </select>
                <p class="mt-1"><b>Note :</b>You can always add/change the instructor later</p>
            </div>

            <div class="d-flex align-items-center gap-4 mt-4">
                <div>
                    <p class="mb-1 text-black">Course Image</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="courseImgSrc" v-if="courseImgSrc" class="h-100 w-100" style="object-fit: cover;"
                                alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importCourseImg">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleCourseImgUpload" ref="courseImg" class="d-none">
                    </div>
                </div>

                <div>
                    <p class="mb-1 text-black">Course Logo</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="courseLogoSrc" v-if="courseLogoSrc" class="h-100 w-100" style="object-fit: cover;"
                                alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importCourseLogo">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleCourseLogoUpload" ref="courseLogo" class="d-none">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-4 mt-4">
                <button class="btn btn-primary btn-lg w-100">Save Course</button>
            </div>
        </Modal>

        <LessonModal :lesson="selecetdLesson" />

        <div class="modal fade" id="removeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="removeModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Delete Confirmation</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete that item?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteItem" >Delete</button>
                </div>
                </div>
            </div>
        </div>

        <!-- lession delete -->
        <div class="modal fade" id="removeLessonModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="removeLessonModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Delete Confirmation</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete that item?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteLesson" >Delete</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import Modal from '../../../components/Common/Modal.vue';
import CourseList from './components/CourseList.vue';
import { RouterLink, useRoute, useRouter } from 'vue-router';
import { computed, onMounted, ref ,watch} from 'vue';
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';
import LessonModal from './components/LessonModal.vue';

const route = useRoute()
const router = useRouter()
const store = useStore()
const selectedItmeId = ref(null)
const selecetdLesson = ref({})
// const course_id = ref(null)


const courseImg = ref(null)
const courseLogo = ref(null)

const courseImgSrc = ref(null)
const courseLogoSrc = ref(null)

const importCourseImg = () => {
    courseImg.value.click()
};
const importCourseLogo = () => {
    courseLogo.value.click()
};

const handleCourseImgUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        courseImgSrc.value = URL.createObjectURL(file);
    }
}

const handleCourseLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        courseLogoSrc.value = URL.createObjectURL(file);
    }
}
const editMembershipName = ref('')

const membershipData = computed(() => store.getters["membership/getSelected"]);

watch(membershipData, (newValue) => {
    editMembershipName.value = newValue.name;
}, { immediate: true });

const changeMembershipName = () => {
    let payload = {
        ...membershipData.value,
        name : editMembershipName.value
    }
    store.dispatch("membership/update", payload)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
        .finally(() => {
            isEditMembershipName.value = false
        })
}

const coursesList = computed(() => store.getters["courses/getCoursesWithSort"])
const isEditMembershipName = ref(false)

onMounted(() => {    
    store.dispatch("membership/getById", route.params.membership_id)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
            router.push({name : 'ErrorPage'})
        })
        
    store.dispatch("courses/get", route.params.membership_id)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
})

const deleteItem = () => {
    if (selectedItmeId.value) {
        store.dispatch("courses/remove", {id : selectedItmeId.value})
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Data remove.');
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
    }
}

const deleteLesson = () => {
    if (selecetdLesson.value) {
        store.dispatch("courses/removeLesson", selecetdLesson.value)
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Data remove.');
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
    }
}

</script>

<style scoped>
.head-title {
    color: #0d6ffd;
    font-weight: 800;
}

.membership-name {
    font-weight: 800;
    color: black;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.membership-name svg {
    width: 12px;
    cursor: pointer;
}

.create-course-btn svg {
    width: 18px;
    fill: white;
    margin: 8px 0 12px;
}

.import-course-btn svg {
    width: 24px;
    margin: 8px 0 12px;
}

#select-course {
    max-width: 280px;
    width: 100%;
    border: none;
    padding: 6px;
    background-color: #f5f6f8;
    border: 1px solid #eee;
    border-radius: 5px;
}

.input-filed {
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-uploader-icon {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-110%, 10%);
    width: 22px;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.image-uploader-icon svg {
    width: 10px;
}

.image-uploader-div {
    border: 1px solid #ccc;
    max-width: 140px;
    width: 140px;
    height: 85px;
}

.membership-input {
    border: none;
    border-bottom: 2px dashed #ccc;
    font-size: 15px;
    text-align: right;
    font-weight: 700;
    max-width: 140px;
}
</style>
<template>
    <header class="shadow-sm is-sticky navbar-area" style="z-index: 50;">
        <div class="vdemy-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <img src="../../assets/img/logo.png" alt="logo" />
                    </router-link>
                    <div class="navbar-toggler" @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ active: button_active_state }"
                        v-on:click="button_active_state = !button_active_state">
                        <i class="bx bx-menu"></i>
                        <i class="bx bx-x"></i>
                    </div>
                    <div class="collapse navbar-collapse" :class="{ toggler: active }">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    My Members
                                    <i class="bx bx-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/" class="nav-link">eLearning School</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/vendor-certification" class="nav-link">Vendor Certification
                                            eTraining</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/online-training" class="nav-link">Online Training
                                            School</router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    Integrations
                                    <i class="bx bx-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/success-story" class="nav-link">Success Story</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/advisor" class="nav-link">Teacher</router-link>
                                    </li>
                                    <li class="nav-item">
                                        <router-link to="/trainer" class="nav-link">Trainer</router-link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>

                    <div class="others-option d-flex align-items-center">
                        <ul class="navbar-nav d-flex flex-row align-items-center">
                            <li class="gc-icon-list">
                                <gc-icon width="36" height="36"></gc-icon>
                            </li>
                            <li class="ps-lg-4 pe-lg-0 px-3 user-icon">
                                <a href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="avtar-svg" viewBox="0 0 512 512">
                                        <path
                                            d="M399 384.2C376.9 345.8 335.4 320 288 320l-64 0c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z" />
                                    </svg>
                                </a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item py-3">
                                        <b class="m-0 ms-3 lh-sm">{{ userDetails.name }}</b>
                                        <p class="m-0 ms-3 lh-1">{{ userDetails.email }}</p>
                                    </li>
                                    <li class="nav-item border-top">
                                        <a class="nav-link" style="cursor: pointer;" @click="logout" >Logout</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    </header>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from "vue-router";
import { toast } from 'vue3-toastify';
const store = useStore();
const router = useRouter();

const active = ref(false);
const button_active_state = ref(false);

const userDetails = computed(() => store.getters["auth/getUser"])

const logout = () => {
    store.dispatch("auth/logout")
        .then(({ data }) => {
            toast.success(data.message || 'Logged out successfully.');

            setTimeout(() => {
                store.dispatch("auth/clearAuth")
                router.push({ name: 'Login' })
            }, 500);
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
};
</script>


<style scoped>
.nav-link {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

.dropdown-menu {
    margin-top: 0 !important;
    padding-top: 0 !important;
    top: 60px !important;
}

.gc-icon-list {
    padding-top: 5px;
}

.user-icon {
    position: relative;
}

.user-icon .dropdown-menu {
    border: none;
    top: 70px;
    right: 0;
    z-index: 99;
    opacity: 0;
    width: 270px;
    display: block;
    border-radius: 0;
    padding: 0;
    margin-top: 15px;
    visibility: hidden;
    position: absolute;
    background: var(--whiteColor);
    transition: all 0.2s ease-in-out;
    border-top: 3px solid var(--mainColor);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1)
}

.user-icon>a:hover+.dropdown-menu,
.user-icon>.dropdown-menu:hover {
    opacity: 100;
    visibility: visible;
}

@media screen and (max-width : 991px) {
    .dropdown-menu {
        margin-top: 10px !important;
        top: 45px !important;
    }

    .gc-icon-list {
        padding-top: 0;
        margin-top: -2px;
    }

    .user-icon {
        margin-top: -8px;
    }
}

.avtar-svg {
    width: 36px;
    fill: #ccc;
}
</style>
<template>
    <div class="container h-100 d-grid" style="place-items: center;">
        <div class="w-100" style="max-width: 480px;">
            <div class="py-4 d-flex justify-content-center">
                <img src="../../assets/img/logo.png" alt="logo" />
            </div>
            <div class="login-form me-0">
                <h2>Login</h2>
                <form @submit.prevent="submit">
                    <div class="form-group">
                        <label>Email</label>
                        <input type="email" class="form-control" v-model="form.email"
                            :class="{ invalid: $v.email.$error }" placeholder="Email">
                        <span v-if="$v.email.$error">
                            {{ $v.email.required.$invalid ? "Email is required" : "" }}
                            {{ $v.email.email.$invalid ? "Invalid email address" : "" }}
                        </span>
                    </div>
                    <div class="form-group">
                        <label>Password</label>
                        <input type="password" class="form-control" v-model="form.password"
                            :class="{ invalid: $v.password.$error }" placeholder="Password">
                        <span v-if="$v.password.$error">
                            {{ $v.password.required.$invalid ? "Password is required" : "" }}
                        </span>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                            <p>
                                <input type="checkbox" id="test2">
                                <label for="test2">Remember me</label>
                            </p>
                        </div>
                        <!-- <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                            <a href="#" class="lost-your-password">Lost your password?</a>
                        </div> -->
                    </div>
                    <button type="submit" :disabled="isLoading">
                        <span v-if="isLoading" class="spinner-border spinner-border-sm text-light me-2" role="status"
                            aria-hidden="true"></span>
                        Log In
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';
import { useRouter } from "vue-router";
const store = useStore();
const router = useRouter()

const isLoading = ref(false);
const form = ref({
    email: null,
    password: null,
});

const rules = computed(() => {
    return {
        email: { required, email },
        password: { required },
    }
})

const $v = useVuelidate(rules, form);

const submit = () => {
    $v.value.$validate();
    if ($v.value.$invalid) {
        return
    }

    isLoading.value = true
    store.dispatch("auth/login", form.value)
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Login successful.');

                setTimeout(() => {
                    const { token, user } = data.data;
                    store.dispatch('auth/setAuth', { token, user })
                    router.push({ name: 'Memberships' })
                }, 500);
            } else {
                isLoading.value = false
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            isLoading.value = false
            toast.error(e.response.data.message || "Error");
        })
};

router.afterEach((to, from) => {
  isMembershipLayout.value = to.name == 'Memberships'
})
</script>

<style>
.invalid {
    border: 1px solid red !important;
}

span {
    color: red;
    font-size: 0.9em;
    margin-top: 6px;
}
</style>
import axios from 'axios';
import store from '@/store'
const customAxios = axios.create({
    baseURL: "https://apicourse.globalcontrol.io/api",
});
const requestHandler = request => {
    let idToken = store.state.auth.token;
    request.headers.Authorization = 'Bearer ' + idToken;
    return request;
};
const responseHandler = response => {
    let idToken = store.state.auth.token;
    if (response.status == 401 && idToken) {
        store.dispatch('auth/logout')
            .finally(() => {
                store.dispatch('auth/clearAuth')
            })
    }
    return response;
};

const errorHandler = error => {
    let idToken = store.state.auth.token;
    if (error.response.status == 401 && idToken) {
        store.dispatch('auth/logout')
            .finally(() => {
                store.dispatch('auth/clearAuth')
            })
    }
    return Promise.reject(error);
};

customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);


export default customAxios;
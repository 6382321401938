<template>
    <h4 class="title">Titanium Members Area</h4>
    <p><span class="text-secondary">Home</span> / <span class="text-dark fw-normal">Membership </span></p>

    <div class="mt-4">
        <h6 class="fw-semibold text-dark mb-3">Courses You Have Access To</h6>
        <div class="row">
            <div class="col-xxl-3 col-lg-4 col-md-6 col-12" v-for="i in 8">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <router-link to="/single-courses-one" class="d-block image">
                            <img src="../../assets/img/courses/img1.jpg" alt="image" />
                        </router-link>
                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="progress bg-light w-100">
                            <div class="bg-primary" style="width: 70%;"></div>
                        </div>
                    </div>
                    <div class="courses-content position-relative p-3">
                        <p class="last-access-content text-secondary fw-medium">Last Access: 12/11/24</p>
                        <div class="course-author d-flex align-items-center">
                            <img src="../../assets/img/user/user1.jpg" class="rounded-circle" alt="image" />
                            <span class="text-primary">Alex Morgan</span>
                        </div>
                        <h3 class="fs-5 text-primary">
                            <router-link to="/single-courses-one" class="line-clamp-2">
                                Titanium tech Call
                            </router-link>
                        </h3>
                        <p class="description line-clamp-3">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                            eiusmod tempor incididunt ut incididunt ut
                        </p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class="flaticon-agenda text-primary"></i> 15 Lessons</li>
                            <li><i class="flaticon-people text-primary"></i> 145 Students</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.title {
    line-height: 90%;
    font-weight: 800;
}

.fav {
    top: 12px !important;
    right: 12px !important;
    font-size: 22px !important;
}

.courses-box-footer li {
    font-size: 14px !important;
}

.courses-content .description {
    font-size: 15px !important;
    line-height: 1.3 !important;
}

.course-author img {
    height: 25px !important;
    width: 25px !important;
}

.course-author span {
    font-size: 14px !important;
    margin: 0 !important;
}

.progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    z-index: 99;
    border-radius: 0;
}

.last-access-content {
    font-size: 12px !important;
    position: absolute;
    top: 10px;
    right: 8px;
    margin: 0;
}

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    line-clamp: 3;
    -webkit-line-clamp: 3;
}

.courses-content h3 a:hover {
    color: currentColor !important;
}
</style>
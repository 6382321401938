import axios from '../../plugins/axios';

const state = {
  user: null,
  token: null,
};

const getters = {
  isAuthenticated: (state) => !!state.token,
  getUser: (state) => state.user,
};

const actions = {
  async login({ commit }, payload) {
    return await axios.post('/login', payload);
  },

  async logout({ commit }) {
    return await axios.post('/logout');
  },

  setAuth({ commit }, payload) {
    commit('SET_AUTH', { 
      token: payload.token, 
      user: payload.user 
    });
  },

  clearAuth({ commit }) {
    commit('CLEAR_AUTH');
  }
};

const mutations = {
  SET_AUTH(state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  CLEAR_AUTH(state) {
    state.token = null;
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

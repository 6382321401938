import { createWebHistory, createRouter } from "vue-router";
import store from "../store"

import eLearningSchoolPage from "../components/Pages/eLearningSchoolPage";
import VendorCertificationETrainingPage from "../components/Pages/VendorCertificationETrainingPage";
import OnlineTrainingSchoolPage from "../components/Pages/OnlineTrainingSchoolPage";
import DistanceLearningPage from "../components/Pages/DistanceLearningPage";
import LanguageSchoolPage from "../components/Pages/LanguageSchoolPage";
import ModernSchoolingPage from "../components/Pages/ModernSchoolingPage";
import YogaTrainingPage from "../components/Pages/YogaTrainingPage";
import HealthCoachingPage from "../components/Pages/HealthCoachingPage";
import KindergartenPage from "../components/Pages/KindergartenPage";
import GymCoachingPage from "../components/Pages/GymCoachingPage";
import LearningManagementPage from "../components/Pages/LearningManagementPage";
import BusinessCoach from "../components/Pages/BusinessCoach";
import AboutPageOne from "../components/Pages/AboutPageOne";
import AboutPageTwo from "../components/Pages/AboutPageTwo";
import AboutPageThree from "../components/Pages/AboutPageThree";
import AboutPageFour from "../components/Pages/AboutPageFour";
import AboutPageFive from "../components/Pages/AboutPageFive";
import SuccessStoryPage from "../components/Pages/SuccessStoryPage";
import AdvisorPage from "../components/Pages/AdvisorPage";
import TrainerPage from "../components/Pages/TrainerPage";
import GalleryPage from "../components/Pages/GalleryPage";
import FaqPage from "../components/Pages/FaqPage";
import ContactPage from "../components/Pages/ContactPage";
import AuthenticationPage from "../components/Pages/AuthenticationPage";
import ErrorPage from "../components/Pages/ErrorPage";
import ComingSoonPage from "../components/Pages/ComingSoonPage";
import PurchaseGuidePage from "../components/Pages/PurchaseGuidePage";
import PrivacyPolicyPage from "../components/Pages/PrivacyPolicyPage";
import TermsServicePage from "../components/Pages/TermsServicePage";
import CoursesPageOne from "../components/Pages/CoursesPageOne";
import CoursesPageTwo from "../components/Pages/CoursesPageTwo";
import CoursesPageThree from "../components/Pages/CoursesPageThree";
import CoursesPageFour from "../components/Pages/CoursesPageFour";
import CoursesPageFive from "../components/Pages/CoursesPageFive";
import CoursesPageSix from "../components/Pages/CoursesPageSix";
import SingleCoursesPageOne from "../components/Pages/SingleCoursesPageOne";
import SingleCoursesPageTwo from "../components/Pages/SingleCoursesPageTwo";
import CategoriesPage from "../components/Pages/CategoriesPage";
import MembershipLevelsPage from "../components/Pages/MembershipLevelsPage";
import BecomeTeacherPage from "../components/Pages/BecomeTeacherPage";
import ProfilePage from "../components/Pages/ProfilePage";
import EventsPage from "../components/Pages/EventsPage";
import EventsDetailsPage from "../components/Pages/EventsDetailsPage";
import BlogPageOne from "../components/Pages/BlogPageOne";
import BlogPageTwo from "../components/Pages/BlogPageTwo";
import BlogPageThree from "../components/Pages/BlogPageThree";
import BlogPageFour from "../components/Pages/BlogPageFour";
import BlogDetailsPageOne from "../components/Pages/BlogDetailsPageOne";
import BlogDetailsPageTwo from "../components/Pages/BlogDetailsPageTwo";
import BlogDetailsPageThree from "../components/Pages/BlogDetailsPageThree";
import ShopPageOne from "../components/Pages/ShopPageOne";
import ShopPageTwo from "../components/Pages/ShopPageTwo";
import CartPage from "../components/Pages/CartPage";
import CheckoutPage from "../components/Pages/CheckoutPage";
import ProductsDetailsPage from "../components/Pages/ProductsDetailsPage";
import App from "../layouts/App.vue";
import Site from "../layouts/UserApp.vue";
import Step1 from "../views/publication/step1/index.vue";
import Login from "../views/auth/Login.vue";
import Memberships from "../views/Memberships/index.vue";
import FrontEndMembership from "../views/FrontEndMemberships/index.vue";
import FrontEndCourse from "../views/FrontEndMemberships/Course.vue";
import FrontEndLession from "../views/FrontEndMemberships/Lession.vue";
import Course from "../views/Course/index.vue";

const routes = [
  { path: "/", name: "eLearningSchoolPage", component: eLearningSchoolPage },
  {
    path: "/vendor-certification",
    name: "VendorCertificationETrainingPage",
    component: VendorCertificationETrainingPage,
  },
  {
    path: "/online-training",
    name: "OnlineTrainingSchoolPage",
    component: OnlineTrainingSchoolPage,
  },
  {
    path: "/distance-learning",
    name: "DistanceLearningPage",
    component: DistanceLearningPage,
  },
  {
    path: "/language-school",
    name: "LanguageSchoolPage",
    component: LanguageSchoolPage,
  },
  {
    path: "/modern-schooling",
    name: "ModernSchoolingPage",
    component: ModernSchoolingPage,
  },
  {
    path: "/yoga-training",
    name: "YogaTrainingPage",
    component: YogaTrainingPage,
  },
  {
    path: "/health-coaching",
    name: "HealthCoachingPage",
    component: HealthCoachingPage,
  },
  {
    path: "/kindergarten",
    name: "KindergartenPage",
    component: KindergartenPage,
  },
  {
    path: "/gym-coaching",
    name: "GymCoachingPage",
    component: GymCoachingPage,
  },
  {
    path: "/learning-management",
    name: "LearningManagementPage",
    component: LearningManagementPage,
  },
  {
    path: "/business-coach",
    name: "BusinessCoach",
    component: BusinessCoach,
  },
  { path: "/about-one", name: "AboutPageOne", component: AboutPageOne },
  { path: "/about-two", name: "AboutPageTwo", component: AboutPageTwo },
  { path: "/about-three", name: "AboutPageThree", component: AboutPageThree },
  { path: "/about-four", name: "AboutPageFour", component: AboutPageFour },
  { path: "/about-five", name: "AboutPageFive", component: AboutPageFive },
  {
    path: "/success-story",
    name: "SuccessStoryPage",
    component: SuccessStoryPage,
  },
  { path: "/advisor", name: "AdvisorPage", component: AdvisorPage },
  { path: "/trainer", name: "TrainerPage", component: TrainerPage },
  { path: "/gallery", name: "GalleryPage", component: GalleryPage },
  { path: "/faq", name: "FaqPage", component: FaqPage },
  { path: "/contact", name: "ContactPage", component: ContactPage },
  {
    path: "/authentication",
    name: "AuthenticationPage",
    component: AuthenticationPage,
  },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
  { path: "/coming-soon", name: "ComingSoonPage", component: ComingSoonPage },
  {
    path: "/purchase-guide",
    name: "PurchaseGuidePage",
    component: PurchaseGuidePage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPage",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-of-service",
    name: "TermsServicePage",
    component: TermsServicePage,
  },
  { path: "/courses-one", name: "CoursesPageOne", component: CoursesPageOne },
  { path: "/courses-two", name: "CoursesPageTwo", component: CoursesPageTwo },
  {
    path: "/courses-three",
    name: "CoursesPageThree",
    component: CoursesPageThree,
  },
  {
    path: "/courses-four",
    name: "CoursesPageFour",
    component: CoursesPageFour,
  },
  {
    path: "/courses-five",
    name: "CoursesPageFive",
    component: CoursesPageFive,
  },
  { path: "/courses-six", name: "CoursesPageSix", component: CoursesPageSix },
  {
    path: "/single-courses-one",
    name: "SingleCoursesPageOne",
    component: SingleCoursesPageOne,
  },
  {
    path: "/single-courses-two",
    name: "SingleCoursesPageTwo",
    component: SingleCoursesPageTwo,
  },
  { path: "/categories", name: "CategoriesPage", component: CategoriesPage },
  {
    path: "/membership-levels",
    name: "MembershipLevelsPage",
    component: MembershipLevelsPage,
  },
  {
    path: "/become-a-teacher",
    name: "BecomeTeacherPage",
    component: BecomeTeacherPage,
  },
  { path: "/profile", name: "ProfilePage", component: ProfilePage },
  { path: "/events", name: "EventsPage", component: EventsPage },
  {
    path: "/single-events",
    name: "EventsDetailsPage",
    component: EventsDetailsPage,
  },
  { path: "/blog-one", name: "BlogPageOne", component: BlogPageOne },
  { path: "/blog-two", name: "BlogPageTwo", component: BlogPageTwo },
  { path: "/blog-three", name: "BlogPageThree", component: BlogPageThree },
  { path: "/blog-four", name: "BlogPageFour", component: BlogPageFour },
  {
    path: "/single-blog-one",
    name: "BlogDetailsOne",
    component: BlogDetailsPageOne,
  },
  {
    path: "/single-blog-two",
    name: "BlogDetailsTwo",
    component: BlogDetailsPageTwo,
  },
  {
    path: "/single-blog-three",
    name: "BlogDetailsThree",
    component: BlogDetailsPageThree,
  },
  { path: "/products-list-one", name: "ShopPageOne", component: ShopPageOne },
  { path: "/products-list-two", name: "ShopPageTwo", component: ShopPageTwo },
  { path: "/cart", name: "CartPage", component: CartPage },
  { path: "/checkout", name: "CheckoutPage", component: CheckoutPage },
  {
    path: "/single-products/:id",
    name: "ProductsDetailsPage",
    component: ProductsDetailsPage,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/app",
    name: "Dashboard",
    component: App,
    meta: {
      requiresAuth: true,
    },
    children : [
      {
        path: "memberships",
        name: "Memberships",
        component: Memberships,
      },
      {
        path: "membership/:membership_id/courses",
        name: "Courses",
        component: Course,
      },
      {
        path: "membership/:membership_id/course/step1",
        name: "MembershipStep1",
        component: Step1,
      }
    ]
  },
  {
    path : '/site',
    name : 'App',
    component : Site,
    meta: {
      requiresAuth: true,
    },
    children : [
      {
        path: "membership",
        name: "Membership",
        component: FrontEndMembership,
      },
      {
        path: "course",
        name: "SiteCourse",
        component: FrontEndCourse,
      },
      {
        path: "lession",
        name: "SiteLession",
        component: FrontEndLession,
      },
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.state.auth.token
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const { path, name, params } = to
  if (isLoggedIn && ["Login"].includes(name)) {
    return next({name : 'Memberships'})
  } else if (requiresAuth && !isLoggedIn) {
    return next("/login")
  }
  return next()
})

export default router;

import axios from '../../plugins/axios';

const state = {
    memberships: [],
    selected: {} 
};

const getters = {
    getMemberships: (state) => state.memberships,
    getSelected: (state) => state.selected
};

const actions = {
    async get({ commit }) {
        const res = await axios.get('/membership/list');
        if (res.data.data) {
            commit('SET_MEMBERSHIP', res.data.data);
        }
        return res;
    },

    async add({ commit }, payload) {
        const res = await axios.post('/membership/add', payload);
        if (res.data.data) {
            commit('ADD_MEMBERSHIP', res.data.data);
        }
        return res;
    },

    async remove({ commit }, payload) {
        const res = await axios.post('/membership/delete', payload);
        if (res.data.status) {
            commit('REMOVE_MEMBERSHIP', payload.id);
        }
        return res;
    },

    async getById({ commit }, id) {
        const res = await axios.get(`/membership/get/${id}`);
        if (res.data.status) {
            commit('SELECT_MEMBERSHIP', res.data.data);
        }
        return res;
    },

    update({ commit }, payload) {
        commit('SELECT_MEMBERSHIP', payload);
    }
};

const mutations = {
    SET_MEMBERSHIP(state, memberships_data) {
        state.memberships = memberships_data;
    },
    ADD_MEMBERSHIP(state, memberships_data) {
        state.memberships.push(memberships_data);
    },
    REMOVE_MEMBERSHIP(state, memberships_id) {
        state.memberships = state.memberships.filter(membership => membership.id != memberships_id);
    },
    SELECT_MEMBERSHIP(state, membership_data) {
        state.selected = membership_data;
    },
    UPDATE_MEMBERSHIP(state, membership_data) {
        state.memberships = state.memberships.map(membership => 
            membership.id == membership_data.id ? membership_data : membership
        );
        state.selected = membership_data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

<template>
    <Modal ref="modal">
        <form @submit.prevent="submit">
            <div class="d-flex justify-content-between align-items-center gap-4">
                <h2><b>Create Membership</b></h2>
                <button class="btn btn-light" type="button" ref="closeBtn" data-bs-dismiss="modal" @click="resetForm" >
                    <font-awesome-icon :icon="['fas', 'xmark']" />
                </button>
            </div>

            <div class="mt-4">
                <label for="name" class="form-label">Membership Name</label>
                <input type="text" placeholder="Membership Name" v-model="form.name" :class="{ invalid: $v.name.$error }" class="input-filed" id="name">
                <span v-if="$v.name.$error">
                    {{ $v.name.required.$invalid ? "Membership Name is required" : "" }}
                </span>
            </div>
            <div class="mt-3">
                <label for="name" class="form-label">Membership URL</label>
                <div class="membership-url-group" :class="{ invalid: $v.url.$error }">
                    <p class="mb-0">http://</p>
                    <input type="text" placeholder="Your Subdomain Name" v-model="form.url" class="input-filed" id="name">
                    <p>.memberportal.io</p>
                </div>
                <span v-if="$v.url.$error">
                    {{ $v.url.required.$invalid ? "Membership URL is required" : "" }}
                    {{ $v.url.regex.$invalid ? "Invalid URL format" : "" }}
                </span>
            </div>
            <div class="mt-3">
                <label for="name" class="form-label">Membership Title</label>
                <input type="text" placeholder="Membership Title" v-model="form.title" class="input-filed" id="name">
            </div>
            <div class="mt-3">
                <label for="description" class="form-label">Membership Description</label>
                <textarea class="input-filed" placeholder="Membership Description" v-model="form.description" id="description" rows="3"></textarea>
            </div>

            <div class="d-flex align-items-center gap-4 mt-4">
                <div>
                    <p class="mb-1 text-black">Membership Image</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="membershipImgSrc" v-if="membershipImgSrc" class="h-100 w-100"
                                style="object-fit: cover;" alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importMembershipImg">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleMembershipImgUpload" ref="membershipImg" class="d-none" accept="image/*">
                        <span v-if="$v.image.$error" >
                            {{ $v.image.required.$invalid ? "Image is required." : "" }}
                            {{ $v.image.isImage.$invalid ? "Must an image." : "" }}
                            {{ $v.image.maxSize.$invalid ? "Size under 2MB." : "" }}
                        </span>
                    </div>
                </div>

                <div>
                    <p class="mb-1 text-black">Membership Logo</p>
                    <div class="image-uploader-div rounded-2 position-relative">
                        <div class="bg-secondary rounded-2 overflow-hidden h-100">
                            <img :src="membershipLogoSrc" v-if="membershipLogoSrc" class="h-100 w-100"
                                style="object-fit: cover;" alt="default Image">
                            <img src="../../../assets/img/no-image.png" v-else class="h-100" style="object-fit: cover;"
                                alt="default Image">
                        </div>
                        <div class="image-uploader-icon" @click="importMembershipLogo">
                            <font-awesome-icon :icon="['fas', 'pen']" />
                        </div>
                        <input type="file" @change="handleMembershipLogoUpload" ref="membershipLogo" class="d-none" accept="image/*">
                        <span v-if="$v.logo.$error">
                            {{ $v.logo.required.$invalid ? "Logo is required." : "" }}
                            {{ $v.logo.isImage.$invalid ? "Must an image." : "" }}
                            {{ $v.logo.maxSize.$invalid ? "Size under 2MB." : "" }}
                        </span>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center gap-4 mt-4" :class="$v.logo.$error || $v.image.$error ? 'pt-2' : ''">
                <button class="btn btn-primary btn-lg text-sm w-100" :disabled="isLoading"><span v-if="isLoading" class="spinner-border spinner-border-sm text-light me-2" role="status"
                    aria-hidden="true"></span>Save Membership</button>
            </div>
        </form>
    </Modal>
</template>

<script setup>
import { ref, computed} from 'vue';
import Modal from '../../../components/Common/Modal.vue';
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';

const store = useStore();

const form = ref({
    name: null,
    url: null,
    title: null,
    description: null,
    logo : null,
    image : null
});

const closeBtn = ref(null)

const imageValidator = helpers.withParams(
  { type: 'image' },
  (value) => {
    return value ? ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'].includes(value.type) : true
  }
)

const maxSizeValidator = helpers.withParams(
  { max: 2048 },
  (value) => {
    return value ? value.size <= 2048 * 1024 : true
  }
)

const rules = computed(() => {
    return {
        name: { required },
        url: { required, regex : helpers.regex(/^(?!-)(?:[A-Za-z0-9-]{2,253})(?<!-)$/) },
        logo: {
            required,
            isImage: imageValidator,
            maxSize: maxSizeValidator
        },
        image: {
            required,
            isImage: imageValidator,
            maxSize: maxSizeValidator
        }
    }
})

const $v = useVuelidate(rules, form);

const membershipImg = ref(null)
const membershipLogo = ref(null)

const membershipImgSrc = ref(null)
const membershipLogoSrc = ref(null)

const importMembershipImg = () => {
    membershipImg.value.click()
};
const importMembershipLogo = () => {
    membershipLogo.value.click()
};

const isLoading = ref(false);
const handleMembershipImgUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        membershipImgSrc.value = URL.createObjectURL(file);
        form.value.image = file
    }
}

const handleMembershipLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        membershipLogoSrc.value = URL.createObjectURL(file);
        form.value.logo = file
    }
}

const resetForm = () => {
    $v.value.$reset();
    form.value.name = null
    form.value.url = null
    form.value.title = null
    form.value.description = null
    form.value.logo = null
    form.value.image = null
    membershipImgSrc.value = null
    membershipLogoSrc.value = null
}

const submit = () => {
    $v.value.$validate();
    if ($v.value.$invalid) {
        return
    }
    isLoading.value = true
    const formData = new FormData();

    formData.append('sub_domain', form.value.url);
    formData.append('name', form.value.name);
    formData.append('title', form.value.title);
    formData.append('description', form.value.description);
    formData.append('logo', form.value.logo ? form.value.logo : '');
    formData.append('image', form.value.image ? form.value.image : '');

    store.dispatch("membership/add", formData)
        .then(({ data }) => {
            isLoading.value = false
            if (data.status) {
                toast.success(data.message || 'Membership added.');
                closeBtn.value.click()
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            isLoading.value = false
            toast.error(e.response.data.message || "Error");
        })
}
</script>

<style scoped>
.input-filed {
    width: 100%;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.image-uploader-icon {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translate(-110%, 10%);
    width: 22px;
    aspect-ratio: 1;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 50%;
    display: grid;
    place-items: center;
    cursor: pointer;
}

.image-uploader-icon svg {
    width: 10px;
}

.image-uploader-div {
    border: 1px solid #ccc;
    max-width: 140px;
    width: 140px;
    height: 85px;
}

.membership-url-group {
    display: flex;
    gap: 8px;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    color: black;
}

.membership-url-group input {
    border-top: none;
    border-bottom: none;
    border-radius: 0;
}

.invalid {
    border: 1px solid red !important;
}

span {
    color: red;
    font-size: 0.9em;
    margin-top: 6px;
}
</style>
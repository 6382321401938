import axios from '../../plugins/axios';

const state = {
    courses: [],
    instructors: []
};

const getters = {
    getCourses: (state) => state.courses,
    getCoursesWithSort: (state) => state.courses.sort((a, b) => a.drag_index - b.drag_index),
    getInstructor: (state) => state.instructors,
};

const actions = {
    async get({ commit }, membership_id) {
            let res = await axios.get(`/course/list/${membership_id}`);
            if (res.data.data) {
                commit('SET_COURSES', res.data.data)
            }
            return res;
    },

    async add({ commit }, payload) {
            let res = await axios.post('/course/add', payload);
            if (res.data.data) {
                commit('ADD_COURSES', res.data.data)
            }
            return res;
    },

    async remove({ commit }, payload) {
            let res = await axios.post('/course/delete', payload);
            if (res.data.status) {
                commit('REMOVE_COURSES', payload.id)
            }
            return res;
    },

    async updateIndex({ commit }, payload) {
                commit('UPDATE_INDEX', payload)
    },

    async getInstructor({ commit }, membership_id) {
            let res = await axios.get(`/instructor/get/${membership_id}`);
            if (res.data.data) {
                commit('SET_INSTRUCTOR', res.data.data)
            }
            return res;
    },

    async addInstructor({ commit }, payload) {
            let res = await axios.post('/instructor/add', payload);
            if (res.data.data) {
                commit('ADD_INSTRUCTOR', res.data.data)
            }
            return res;
    },

    async removeInstructor({ commit }, payload) {
            let res = await axios.post('/instructor/delete', payload);
            if (res.data.status) {
                commit('REMOVE_INSTRUCTOR', payload.id)
            }
            return res;
       
    },

    async getLesson({ commit }, course_id) {
        let res = await axios.get(`/lesson/get/${course_id}`);
        if (res.data.data) {
            commit('SET_LESSON', course_id, res.data.data)
        }
        return res;
    },

    async addLesson({ commit }, payload) {
        let res = await axios.post('/lesson/add', payload);
        if (res.data.data) {
            commit('ADD_LESSON', res.data.data)
        }
        return res;
    },

    async removeLesson({ commit }, payload) {
        let res = await axios.post('/lesson/delete', payload);
        if (res.data.data) {
            commit('REMOVE_LESSON', payload)
        }
        return res;
    },

    async updateLesson({ commit }, payload) {
        let res = await axios.post('/lesson/update', payload);
        if (res.data.data) {
            commit('UPDATE_LESSON', res.data.data)
        }
        return res;
    },

};

const mutations = {
    SET_COURSES(state, courses_data) {
        state.courses = courses_data;
    },
    ADD_COURSES(state, courses_data) {
        state.courses.push(courses_data);
    },
    REMOVE_COURSES(state, courses_id) {
        state.courses = state.courses.filter(course => course.id != courses_id);
    },
    SET_INSTRUCTOR(state, instructor_data) {
        state.instructors = instructor_data;
    },
    ADD_INSTRUCTOR(state, instructor_data) {
        state.instructors.push(instructor_data);
    },
    REMOVE_INSTRUCTOR(state, instructors_id) {
        state.instructors = state.instructors.filter(instructor => instructor.id != instructors_id);
    },
    UPDATE_INDEX(state, moved_data) {
        const { id , new_index, old_index } = moved_data;
        const course = state.courses.find(course => course.id === id);
        if (!course) {
            return;
        }
        course.drag_index = new_index;

        if (new_index - old_index) {
            state.courses = state.courses.map((course) => course.drag_index <= new_index && course.id != id ? {...course, drag_index : course.drag_index - 1} : course )
        }else{
            state.courses = state.courses.map((course) => course.drag_index > new_index ? {...course, drag_index : course.drag_index + 1} : course )
        }
    },
    SET_LESSON(state, course_id, lesson_data) {
        const course = state.courses.find(course => course.id === course_id);
        course.lessons = lesson_data;
    },
    ADD_LESSON(state, lesson_data) {
        const course = state.courses.find(course => course.id === lesson_data.course_id);
        course.lessons.push(lesson_data);
    },
    REMOVE_LESSON(state, lesson_data) {
        console.log(lesson_data);
        const course = state.courses.find(course => course.id === lesson_data.course_id);
        course.lessons = course.lessons.filter(lesson => lesson.id != lesson_data.id);
        console.log(course.lessons);
        
    },
    UPDATE_LESSON(state, lesson_data) {
        const course = state.courses.find(course => course.id === lesson_data.course_id);
        course.lessons = course.lessons.map(lesson => lesson.id == lesson_data.id ? lesson_data : lesson);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

<template>
    <div style="max-width: 1199px;">
        <div class="d-flex justify-content-between align-items-center gap-4">
            <div class="w-100">
                <p class="m-0 mb-2"><router-link to="/">Home</router-link> / 
                <router-link to="/app/memberships">{{ membershipData.name }}
                </router-link>  / <b> Courses </b></p>
                <h2 class="head-title">My Course</h2>

                <div class="mt-4">
                    <label for="sort-by" class="me-2"><b class="text-secondary">Sort By:</b></label>
                    <select id="sort-by">
                        <option value="1" selected>Default</option>
                    </select>
                </div>
            </div>
            <div class="w-100">
                <div class="membership-name">
                    <p class="m-0" v-if="!isEditMembershipName">{{ membershipData.name }}</p>
                    <input type="text" v-else v-model="editMembershipName" class="membership-input">
                    <font-awesome-icon :icon="['fas', 'pen']" v-if="!isEditMembershipName" @click="isEditMembershipName = true" />
                    <font-awesome-icon :icon="['fas', 'check']" v-else @click="changeMembershipName" />
                </div>
                <div class="d-flex justify-content-end align-items-center gap-2">
                    <button class="btn btn-primary create-course-btn py-2" data-bs-toggle="modal"
                        data-bs-target="#createCourseModal">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M48 24C48 10.7 37.3 0 24 0S0 10.7 0 24L0 64 0 350.5 0 400l0 88c0 13.3 10.7 24 24 24s24-10.7 24-24l0-100 80.3-20.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30l0-279.7c0-23-24.2-38-44.8-27.7l-9.6 4.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L48 52l0-28zm0 77.5l96.6-24.2c27-6.7 55.5-3.6 80.4 8.8c54.9 27.4 118.7 29.7 175 6.8l0 241.8-24.4 9.1c-33.7 12.6-71.2 10.7-103.4-5.4c-48.2-24.1-103.3-30.1-155.6-17.1L48 338.5l0-237z" />
                        </svg>
                        <br>
                        <b class="text-white" style="font-size: 14px;">Create Course</b>
                    </button>
                    <button class="shadow btn btn-white import-course-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path
                                d="M320 96L192 96 144.6 24.9C137.5 14.2 145.1 0 157.9 0L354.1 0c12.8 0 20.4 14.2 13.3 24.9L320 96zM192 128l128 0c3.8 2.5 8.1 5.3 13 8.4C389.7 172.7 512 250.9 512 416c0 53-43 96-96 96L96 512c-53 0-96-43-96-96C0 250.9 122.3 172.7 179 136.4c0 0 0 0 0 0s0 0 0 0c4.8-3.1 9.2-5.9 13-8.4zm84 88c0-11-9-20-20-20s-20 9-20 20l0 14c-7.6 1.7-15.2 4.4-22.2 8.5c-13.9 8.3-25.9 22.8-25.8 43.9c.1 20.3 12 33.1 24.7 40.7c11 6.6 24.7 10.8 35.6 14l1.7 .5c12.6 3.8 21.8 6.8 28 10.7c5.1 3.2 5.8 5.4 5.9 8.2c.1 5-1.8 8-5.9 10.5c-5 3.1-12.9 5-21.4 4.7c-11.1-.4-21.5-3.9-35.1-8.5c-2.3-.8-4.7-1.6-7.2-2.4c-10.5-3.5-21.8 2.2-25.3 12.6s2.2 21.8 12.6 25.3c1.9 .6 4 1.3 6.1 2.1c0 0 0 0 0 0s0 0 0 0c8.3 2.9 17.9 6.2 28.2 8.4l0 14.6c0 11 9 20 20 20s20-9 20-20l0-13.8c8-1.7 16-4.5 23.2-9c14.3-8.9 25.1-24.1 24.8-45c-.3-20.3-11.7-33.4-24.6-41.6c-11.5-7.2-25.9-11.6-37.1-15c0 0 0 0 0 0l-.7-.2c-12.8-3.9-21.9-6.7-28.3-10.5c-5.2-3.1-5.3-4.9-5.3-6.7c0-3.7 1.4-6.5 6.2-9.3c5.4-3.2 13.6-5.1 21.5-5c9.6 .1 20.2 2.2 31.2 5.2c10.7 2.8 21.6-3.5 24.5-14.2s-3.5-21.6-14.2-24.5c-6.5-1.7-13.7-3.4-21.1-4.7l0-13.9z" />
                        </svg>
                        <br>
                        <b style="font-size: 14px;">Import Course</b>
                    </button>
                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-lg-6" v-for="(course, index) in coursesList" :key="index" >
                <CourseGrid @delete="(id) => selectedItmeId = id" :data="course"/>
            </div>
        </div>

        <CreateModal />
        <div class="modal fade" id="removeModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="removeModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5">Delete Confirmation</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to delete that item?</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="deleteItem" >Delete</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref,watch} from 'vue';
import CourseGrid from './components/CourseGrid.vue';
import CreateModal from './components/CreateModal.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from "vuex";
import { toast } from 'vue3-toastify';

const route = useRoute()
const router = useRouter()
const store = useStore()
const selectedItmeId = ref(null)
const editMembershipName = ref('')

const membershipData = computed(() => store.getters["membership/getSelected"]);

watch(membershipData, (newValue) => {
    editMembershipName.value = newValue.name;
}, { immediate: true });

const changeMembershipName = () => {
    let payload = {
        ...membershipData.value,
        name : editMembershipName.value
    }
    store.dispatch("membership/update", payload)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
        .finally(() => {
            isEditMembershipName.value = false
        })
}

const coursesList = computed(() => store.getters["courses/getCourses"])
const isEditMembershipName = ref(false)

onMounted(() => {    
    store.dispatch("membership/getById", route.params.membership_id)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
            router.push({name : 'ErrorPage'})
        })
        
    store.dispatch("courses/get", route.params.membership_id)
        .catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
})

const deleteItem = () => {
    if (selectedItmeId.value) {
        store.dispatch("courses/remove", {id : selectedItmeId.value})
        .then(({ data }) => {
            if (data.status) {
                toast.success(data.message || 'Data remove.');
            } else {
                toast.error(data.message || "Error");
            }
        }).catch((e) => {
            toast.error(e.response.data.message || "Error");
        })
    }
}
</script>

<style scoped>
.head-title {
    color: #0d6ffd;
    font-weight: 800;
}

.membership-name {
    font-weight: 800;
    color: black;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
}

.membership-name svg {
    width: 12px;
    cursor: pointer;
}

.create-course-btn svg {
    width: 16px;
    fill: white;
    margin: 8px 0 12px;
}

.import-course-btn svg {
    width: 24px;
    margin: 8px 0 12px;
}

#sort-by {
    max-width: 220px;
    width: 100%;
    border: none;
    padding: 6px;
    background-color: #f5f6f8;
    border: 1px solid #eee;
    border-radius: 5px;
}

.membership-input {
    border: none;
    border-bottom: 2px dashed #ccc;
    font-size: 15px;
    text-align: right;
    font-weight: 700;
    max-width: 140px;
}
</style>